import { authHeader } from './auth-header';

export const userService = {
    login,
    logout,
    getProfile,
    getAuthToken,
};
//const apiUrl = "https://rmobility.ovh";
//const apiUrl = "https://rmobilitytest.raynis.co";
const apiUrl = "https://rmobility.ovh";

function login(tel, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({username: tel, password })
    };

    return fetch(`${apiUrl}/dash/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.tokenSalt) {
                localStorage.setItem('user', JSON.stringify(user));
                // Store the token in sessionStorage for sharing
                sessionStorage.setItem('sharedAuthToken', user.tokenSalt);
            }
            return user;
        });
}


function logout() {
    localStorage.removeItem('user');
    sessionStorage.removeItem('sharedAuthToken');
}

function getAuthToken() {
    return sessionStorage.getItem('sharedAuthToken');
}
function getProfile() {
    //let user = JSON.parse(localStorage.getItem('user'));
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${apiUrl}/image/${user.userAvatarCode}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}