<template>
  <div id="app">
      <vue-snotify/>
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import Snotify from 'vue-snotify'; 
import 'vue-snotify/styles/material.css'
import Vue from 'vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


Vue.use(VueTelInput);
Vue.use(Snotify)
Vue.config.productionTip = false


export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  }
};
</script>