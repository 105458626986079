import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('auth/logOut')
                    ///store.dispatch('authfack/logout')
                    
                }
              
                // Navigate back to previous page, or home as a fallback
                next('login');
            },
        },
    },
    {
        path: '/',
        name: 'home',
        // meta: {
        //     authRequired: true,
        // },
        component: () => import('../views/pages/dashboard/index')
    },
    //Trajets
    {
        path: '/newtrajet',
        name: 'Nouveau Trajet',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/add')
    },
    {
        path: '/attribuertrajet',
        name: 'Attribution',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/attribuer')
    },
    {
        path: '/trajets/publies',
        name: 'Trajets Publiés',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/publies')
    },
    {
        path: '/trajets/passe',
        name: 'Trajets Passées',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/passe')
    },
    {
        path: '/trajets/encours',
        name: 'Trajets Encours',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/encours')
    },
    {
        path: '/trajets/suivi',
        name: 'Trajets Suivi',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/suivi')
    },
    {
        path: '/trajets/avenir',
        name: 'Trajets à Venir',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/avenir')
    },
    {
        path: '/trajets/effectues',
        name: 'Trajets Effectues',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/effectues')
    },{
        path: '/trajets/mapview',
        name: 'Trajets Maps',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/taxi/trajet-global.vue')
    },
    {
        path: '/trajets/annules',
        name: 'Trajets Annules',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/trajets/annules')
    },

     //status-trajets

     {
        path: '/trajets/status-trajets',
        name: 'status-trajets',
        meta: { authRequired: true },
        component: () => import('../views/pages/trajets/status-trajets.vue')
    },


    //messages
    {
        path: '/message/nouveau',
        name: 'Nouveau Message',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/message/new')
    },
    {
        path: '/message/historique',
        name: 'Historique Message',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/message/historique')
    },
    {
        path: '/auth/login-1',
        name: 'login-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/register-1')
    },
    {
        path: '/auth/lock-screen-1',
        name: 'Lock-screen-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/lock-screen-1')
    },
    {
        path: '/auth/recoverpwd-1',
        name: 'Recoverpwd-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/recoverpwd-1')
    },
    
    //user
    {
        path: '/user/add_users',
        name: 'Nouveau utilisateur',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/addUser')
    },
    {
        path: '/user/users_list',
        name: 'Liste utilisateur',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/UserList')
    },
    {
        path: '/user/supp_users',
        name: 'Supp Utilisateurs',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/userliste_todelete')
    },
    {
        path: '/user/suspended_users',
        name: 'Liste utilisateurs suspendus',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/suspendu')
    },
    {
        path: '/user/user_info/:idUser',
        name: 'infoUserPage',
        meta: { authRequired: true },
        props: route=>({
            idUser: route.params["idUser"]
        }),
        component: () => import('../views/pages/users/userinfo')
    },
    {
        path: '/user/verification/:idUser',
        name: 'Vérification utilisateur',
        meta: { authRequired: true },
        props: route=>({
            idUser: route.params["idUser"]
        }),
        component: () => import('../views/pages/users/verification')
    },
    {
        path: '/user/domicile',
        name: 'Domicile',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/users/domicile')
    },

    {
        path: '/user/avis_user',
        name: 'Domicile',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/users/avis_user')
    },
    //fin user

    //RMO BUS

    {
        path: '/bus/ligne',
        name: 'bus-ligne',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/bus/ligne')
    },



    // RMOBILITY TAXI START

    {
        path: '/taxi/user-list',
        name: 'taxi-user',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/user-list')
    },
    {
        path: '/recherche/taxi',
        name: 'recherche-taxi',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/recherche')
    },
    // {
    //     path: '/taxi/planification',
    //     name: 'taxi-planification',
    //     meta: { authRequired: true },
       
    //     component: () => import('../views/pages/taxi/planification')
    // },
    {
        path: '/recherche/bus',
        name: 'recherche-bus',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/bus/recherche')
    },
    {
        path: '/taxi/demande',
        name: 'taxi-demande',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/demande')
    },
    {
        path: '/taxi/course',
        name: 'taxi-course',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/course')
    },
    {
        path: '/taxi/reservation',
        name: 'taxi-reservation',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/reservation')
    },
    {
        path: '/taxi/suivi',
        name: 'taxi-suivi',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/suivi')
    },
    {
        path: '/taxi/commision',
        name: 'taxi-commission',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/commission')
    },
    {
        path: '/taxi/bonus',
        name: 'taxi-bonus',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/bonus')
    },
    {
        path: '/taxi/gsm',
        name: 'taxi-gsm',
        meta: { authRequired: true },
       
        component: () => import('../views/pages/taxi/gsm')
    },
    {
        path: '/taxi/area',
        name: 'supported-area',
        meta: { authRequired: true },
        component: () => import('../views/pages/taxi/area.vue')
    },
    {
        path: '/taxi/activity-area',
        name: 'activity-area',
        meta: { authRequired: true },
        component: () => import('../views/pages/taxi/activity-area.vue')
    },
    {
        path: '/taxi/globale-vue',
        name: 'global-view',
        meta: { authRequired: true },
        component: () => import('../views/pages/taxi/global-view.vue')
    },
    {
        path: '/taxi/references',
        name: 'taxi-references',
        meta: { authRequired: true },
        component: () => import('../views/pages/taxi/references.vue')
    },



    // RMOBILITY TAXI END

    //Réservation
    {
        path: '/reservation/add_reservation',
        name: 'Réservation',
        meta: { authRequired: true },
        component: () => import('../views/pages/reservation/add')
    },
    {
        path: '/reservation/reservation_list',
        name: 'Réservation',
        meta: { authRequired: true },
        component: () => import('../views/pages/reservation/reservationList')
    },
    // Fin réservation

    //admin
    {
        path: '/admin/add-admin',
        name: 'Administrateur',
        meta: { authRequired: true },
        component: () => import('../views/pages/administrateur/add_admin')
    },
    {
        path: '/admin/taxes',
        name: 'Taxes',
        meta: { authRequired: true },
        component: () => import('../views/pages/administrateur/taxes')
    },
    {
        path: '/feedbacks',
        name: 'Feedback',
        meta: { authRequired: true },
        component: () => import('../views/pages/general/feedback')
    },
    {
        path: '/admin/services',
        name: 'Gestion des services',
        meta: { authRequired: true },
        component: () => import('../views/pages/services/Main.vue')
    },
    {
        path: '/admin/faq',
        name: 'FAQ',
        meta: { authRequired: true },
        component: () => import('../views/pages/clauses/faq.vue')
    },
    {
        path: '/admin/confidentialite',
        name: 'Confidentialite',
        meta: { authRequired: true },
        component: () => import('../views/pages/clauses/confidentialite.vue')
    },
    {
        path: '/admin/notif',
        name: 'Notifications',
        meta: { authRequired: true },
        component: () => import('../views/pages/administrateur/notif')
    },
    //fin admin

    // détails alertes 
    {// -------------------------don 
        path: '/alertes/details_alertes',
        name: 'Details alertes',
        meta: { authRequired: true },
        component: () => import('../views/pages/alertes/details_alertes')
    },
    // fin détails alertes

    //alerte trajet
    {
        path: '/trajets/alertes',
        name: 'alertes trajets',
        meta: { authRequired: true },
        component: () => import('../views/pages/trajets/alerte')
    },

    //fin alerte

    // transaction 
    {
        path: '/transactions/historique-transactions',
        name: 'Transactions',
        meta: { authRequired: true },
        component: () => import('../views/pages/transactions/historique')
    },
    // fin transaction

    // Wallet 
    {
        path: '/wallet/solde',
        name: 'Wallet',
        meta: { authRequired: true },
        component: () => import('../views/pages/wallet/wallet')
    },
    // Fin Wallet

    // Transaction Wallet 
    {
        path: '/wallet/transactions',
        name: 'Wallet',
        meta: { authRequired: true },
        component: () => import('../views/pages/wallet/historique-wallet')
    },
    // Fin Transaction Wallet
    
    //virement
    {
        path: '/virements/historique-virements',
        name: 'Virement',
        meta: { authRequired: true },
        component: () => import('../views/pages/virements/historique')
    },
    {
        path: '/virements/retrait',
        name: 'Retrait',
        meta: { authRequired: true },
        component: () => import('../views/pages/virements/retrait')
    },
    {
        path: '/virements/remboursement',
        name: 'Remboursement',
        meta: { authRequired: true },
        component: () => import('../views/pages/virements/remboursement')
    },
    //fin virement

    // paiement
    {
        path: '/suivis/historique',
        name: 'Suivis de gains',
        meta: { authRequired: true },
        component: () => import('../views/pages/suivis-gains/historique')
    },
    //fin paiment

    //don 
    {
        path: '/dons/add_dons',
        name: 'Ajouter dons',
        meta: { authRequired: true },
        component: () => import('../views/pages/dons/dons')
    },
    {
        path: '/dons/list_dons',
        name: 'Liste dons',
        meta: { authRequired: true },
        component: () => import('../views/pages/dons/list')
    },
    //fin don

    {
        path: '/dons/add_dons',
        name: 'Dons',
        meta: { authRequired: true },
        component: () => import('../views/pages/dons/dons')
    },
    //bon plans
    {
        path: '/general/publicite',
        name: 'Publicité',
        meta: { authRequired: true },
        component: () => import('../views/pages/general/publicite')
    },
    {
        path: '/bon_plans/bons_plans',
        name: 'Bons plans',
        meta: { authRequired: true },
        component: () => import('../views/pages/bon-plans/plan')
    },
    // fin bon plans

    //statistique utilisateur
    {
        path: '/user/statistiques',
        name: 'Statistiques',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/statistique')
    },
    //fin statistique

    // badge
    {
        path: '/user/badge',
        name: 'Statistiques',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/badge')
    },
    // fin badge

    //details trajet_a_venir
    {
        path: '/trajets/details_avenir/:idtrajet',
        name: 'details trajets à venir  ',
        meta: { authRequired: true },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_avenir')
    },
    {
        path: '/trajets/details_effectues/:idtrajet',
        name: 'details trajets effectués',
        meta: { authRequired: true },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_effectues')
    },
    {
        path: '/trajets/details_encours/:idtrajet',
        name: 'details trajets en cours',
        meta: { authRequired: true },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_encours')
    },
    {
        path: '/trajets/details_annules/:idtrajet',
        name: 'details trajets annulés',
        meta: { authRequired: true },
        props: route=>({
            idtrajet: route.params["idtrajet"]
        }),
        component: () => import('../views/pages/trajets/details_annules')
    },
    {
        path: '/trajets/urgences',
        name: 'urgences',
        meta: { authRequired: true },
        component: () => import('../views/pages/trajets/urgences')
    },
    {
        path: '/trajets/recherche',
        name: 'recherche',
        meta: { authRequired: true },
        component: () => import('../views/pages/trajets/recherche')
    },
    {
        path: '/trajets/reservation',
        name: 'reservation',
        meta: { authRequired: true },
        component: () => import('../views/pages/trajets/reservation')
    },
    //fin details trajets_a_venir
    //CodePromo
    {
        path: '/promoannif',
        name: 'promoannif',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/anniversaire')
    },
    {
        path: '/codepromo',
        name: 'codepromo',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/codepromo')
    },
    {
        path: '/parrainage',
        name: 'parrainage',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/codeparrainage')
    },
    {
        path: '/infostrafic',
        name: 'infostrafic',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/infostrafic')
    },

    {
        path: '/actualite',
        name: 'actualite',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/actualite')
    },
    {
        path: '/informations',
        name: 'informations',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/informations')
    },

    {
        path: '/blog',
        name: 'Blogs',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/blog')
    },
    
    {
        path: '/conseil',
        name: 'Conseils',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/conseil')
    },

    {
        path: '/users/gains_parrains',
        name: 'Gains parrains',
        meta: { authRequired: true },
        component: () => import('../views/pages/users/gains_parrains')
    },

    {
        path: '/conseil_conducteur',
        name: 'Conseils conducteurs',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/conseil_conducteur')
    },
    //Location 
    {
        path: '/agence/compagnie',
        name: 'compagnie',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/compagnie')
    },
    {
        path: '/agence/demandes',
        name: 'agencelog',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/demande_agence')
    },
    {
        path: '/location/historique',
        name: 'historique-location',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/historique_location')
    },
    {
        path: '/agence/demandes_user',
        name: 'userdemande',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/demande_user')
    },
    {
        path: '/agence/user_list',
        name: 'user_list',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/user_list')
    },
    {
        path: '/appupload',
        name: 'APK Upload',
        meta: { authRequired: true },
        component: () => import('../views/pages/codepromo/appupload')
    },
    {
        path: '/agence/agencelist',
        name: 'agenceliste',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/agence_list')
    },

    {
        path: '/agence/list_abonne',
        name: 'abonneliste',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/list_abonne')
    },
    {
        path: '/agence/historique_paiement',
        name: 'abonneliste',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/historique_paiement')
    },

    {
        path: '/agence/virements',
        name: 'virement',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/virements')
    },
    {
        path: '/agence/remboursements',
        name: 'remboursement',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/remboursement')
    },

    {
        path: '/agence/discussions',
        name: 'discussions',
        meta: { authRequired: true },
        component: () => import('../views/pages/rmobilitylocation/discussions')
    },
    
    {
        path: '/agence/userlocation_info/:idUser',
        name: 'Information utilisateur',
        meta: { authRequired: true },
        props: route=>({
            idUser: route.params["idUser"]
        }),
        component: () => import('../views/pages/rmobilitylocation/userlocation_info')
    },
    {
        path: '/agence/detail_agence/:row',
        name: 'Agence détails',
        meta: { authRequired: false },
        props: route=>({
            idUser: route.params["row"]
        }),
        component: () => import('../views/pages/rmobilitylocation/detail_agence')
    },

    {
        path: '/discussions/chatactif',
        name: 'Actives',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/discussions/chatactif')
    },
    {
        path: '/event/evenement',
        name: 'Actives',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/event/evenement')
    },

    {
        path: '/event/details/:passId',
        name: 'Pass',
        meta: { authRequired: false },
        props: route=>({
            passId: route.params["passId"]
        }),
       
        component: () => import('../views/pages/event/pass-details')
    },

    {
        path: '/event/pass/buy',
        name: 'buy-list',
        meta: { authRequired: false },
        props: route=>({
            passId: route.params["soldId"]
        }),
       
        component: () => import('../views/pages/event/buy-list')
    },

    ///lots

    {
        path: '/gifts',
        name: 'Gifts',
        meta: { authRequired: false },
        // props: route=>({
        //     passId: route.params["passId"]
        // }),
       
        component: () => import('../views/pages/codepromo/gestion-lots.vue')
    },
///evenement/all-pass 

{
    path: '/event/all-pass/:eventId',
    name: 'event-Pass',
    meta: { authRequired: false },
    props: route=>({
        eventId: route.params["eventId"]
    }),
   
    component: () => import('../views/pages/event/pass-details')
},


    {
        path: '/discussions/chatarchive',
        name: 'Archivés',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/discussions/chatarchive')
    },

    {
        path: '/souscription/list-souscription',
        name: 'Archivés',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/souscription/list-souscription')
    },


    {
        path: '/assurance/option-assurance',
        name: 'Option d\'assurance',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/assurance/option-assurance')
    },


    {
        path: '/souscription/detail-assurance/:idUser',
        name: 'Détail souscription',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/souscription/detail-assurance')
    },


    {
        path: '/nsia/nsia_souscription',
        name: 'Vue NSIA',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/nsia/nsia_souscription')
    },

    {
        path: '/vpi/list_vpi',
        name: 'Vue NSIA',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/vip/list_vip')
    },


    {
        path: '/agence/demande_reservation',
        name: 'Réservation',
        meta: { authRequired: false },
       
        component: () => import('../views/pages/rmobilitylocation/demande_reservation')
    },

    {
        path: '/experience/user_experience/:codeparrainage',
        name: 'Expérience',
        meta: { authRequired: false },
        props: route=>({
            codeparrainage: route.params["codeparrainage"]
        }),
        component: () => import('../views/pages/experience/user_experience')
    },



   
]
