import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'

import * as VueGoogleMaps from 'vue2-google-maps'
import CountryFlag from 'vue-country-flag'
import MazInput  from 'maz-ui'
import vco from "v-click-outside"
import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import "@/assets/scss/app.scss";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";



Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.component('country-flag', CountryFlag)
Vue.config.productionTip = false
Vue.use(BootstrapVue)

Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(MazInput)
Vue.use(Snotify)
Vue.use(require('vue-chartist'))

import Snotify from 'vue-snotify';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDlf2gLsEz37Lsukm_tg26ew5H7QViHSpo',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

// Sentry.init({
//   Vue,
//   dsn: "https://a6b748f284ce3c22fb9b611739f0965e@o4507544503779328.ingest.us.sentry.io/4507544511250432",
//   integrations: [
//     Sentry.browserTracingIntegration({ router }),
//     Sentry.replayIntegration(),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for tracing.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });





Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
